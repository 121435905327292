import React from 'react';
import { Helmet } from 'react-helmet';

//images
import first from '../assets/img/first.jpg';

import concept from '../assets/img/concept.jpg';
import biocoiff from '../assets/img/biocoiff.jpg';
import davines from '../assets/img/davines.jpg';

import salon from '../assets/img/salon.jpg';
import salon2 from '../assets/img/salon2.jpg';
import salon3 from '../assets/img/salon3.jpg';
import salon4 from '../assets/img/salon4.jpg';

//components
import Layout from  '../components/Layout';
import OsmMap from '../components/OsmMap';

//style
import '../assets/style/normalize.css';
import '../assets/style/main.scss';

const Home = () => {
  return(
   <>
    <Helmet>
      <title>V R Nature</title>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
    </Helmet>

    <Layout>
      <div className="section header" id="accueil">
        <h1>V R Nature</h1>
        <h2>Salon de coiffure & bien être</h2>
      </div>

      <div className="section description">
        <p>
          Découvrez une ambiance chaleureuse et offrez vous un moment de détente et de lâcher prise. Toujours à votre écoute je me soucie avant tout de votre sénérité et de votre bien être au naturel.
       </p>
       <h3>On a tou(te)s besoin d'une pause</h3>
      </div>
      
      <div className="section services">
        <h2>Découvrez nos produits et nos soins</h2>
        <h3>Tout est réuni pour vivre une expérience capillaire verte, moderne et joyeuse.</h3>

        <div className="line">
          <img src={concept}/>
          <div className="text">
            <h4>Concept</h4>
            <p>Produits  naturels et naturels bio</p>
            <p>Respectueux de l'environnement et de l'homme</p>
            <p>Éthique</p>
          </div>
        </div>

        <div className="line" id="soinsproduits">
          <div className="text">
            <h4>Nos produits & soins</h4>
            <p>VRNature vous propose une gamme de produits naturels, de qualité, en adéquation avec son éthique, et une kyrielle de prestations : coloration végétale, soin du cuir chevelu, modelage crânien, coupe, sans oublier des produits aux multiples bienfaits pour compléter vos soins.</p>
          </div>
          <img src={biocoiff}/>
        </div>

        <div className="line">
          <img src={davines}/>
          <div className="text">
            <p>Avoir de beaux cheveux, c’est bien, les entretenir avec de bons produits, c’est mieux !</p>
            <p>C’est pourquoi VR Nature travaille avec les marques éco-responsables Davines et Biocoiff</p>
            <ul>
              <li>Biocoiff  c'est du Naturel et bio</li>
              <li>Davines, ce n’est pas qu’une marque au packaging joli et épuré, c’est avant tout la qualité !</li>
            </ul>
          </div>
        </div>

      </div> 
      
      <div className="section hours" id="horaires">
        <h2>Horaires</h2>
        <h3>Sur rendez-vous</h3>
        <div className="days">
          <p>Lundi</p>
          <p>Mardi</p>
          <p>Mercredi</p>
          <p>Jeudi</p>
          <p>Vendredi</p>
          <p>Samedi</p>
        </div>
        <div className="hours">
          <p>10h - 19h</p>
          <p>Fermé</p>
          <p>9h - 18h</p>
          <p>9h - 19h</p>
          <p>9h - 19h</p>
          <p>9h - 18h</p>
        </div>
      </div>
      
      <div className="section saloon" id="lesalon">
        <h2>Le salon en image</h2>
        <h3>Un petit aperçu de l'ambiance.</h3>
        <div className="container"> 
          <img src={salon}/>
          <img src={salon2}/>
          <img src={salon3}/>
          <img src={salon4}/>
        </div>
      </div>
      
      <div className="section contacts" id="contact">
        <h2>V R Nature</h2>
        <p>194 rue Le Quesne</p>
        <p>59700 Marcq en Baroeul</p>
        <p>03 20 98 16 45</p>
      </div>
   
      <div className="section map">
        <OsmMap lat="50.673850" lng="3.096500" text="V & R"/>
      </div>

      <div className="section footer">
        <p>© V & R. All rights reserved. Development & Design: <a href="https://aloisleclet.fr">Aloïs LECLET</a></p>
      </div>
    </Layout>
  </>
  );
}

export default Home;
