import React from 'react'

//images
import first from '../assets/img/first.jpg';
import logo from '../assets/img/logo.png';

//component
import Navbar from '../components/Navbar';

const Layout = ({ children }) => {
  return (
    <div>
      <div className="background-layer">
        <img src={first} className="background"/>
      </div>
      <div>
        <Navbar/>
        {children}
      </div>
    </div>
  )
}

export default Layout;
