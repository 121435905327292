import React from 'react';
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

//images
import instagram from '../assets/img/instagram.svg';
import facebook from '../assets/img/facebook.svg';
import close from '../assets/img/close.svg';

const Navbar = () => {
  
  let openNavbar = () => {
    let nav = document.getElementById('nav');
    nav.classList.add('open');
  };
  
  let closeNavbar = () => {
    let nav = document.getElementById('nav');
    nav.classList.remove('open');
  };
    
  return (
    <div>
      <div className="menu" onClick={ () => openNavbar() }>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      <div className="nav" id="nav">
        <a onClick={ () => closeNavbar() }>
            <img src={close}/>
        </a>
        <AnchorLink to="#accueil">ACCUEIL</AnchorLink>
        <AnchorLink to="#horaires">HORAIRES</AnchorLink>
        <AnchorLink to="#lesalon">LE SALON</AnchorLink>
        <Link to="#soinsproduits">LES SOINS</Link>
        <Link to="#soinsproduits">LES PRODUITS</Link>
        <AnchorLink to="#contact">CONTACT</AnchorLink>

      </div>
    </div>
  )
}
      //@todo to put bellow contact with right links
      //  <div className="social">
      //    <a href="https://www.facebook.com/vr" target="_blank" rel="noreferrer">
      //      <img src={facebook}/>
      //    </a>
      //    <a href="https://www.instagram.com/vr" target="_blank" rel="noreferrer">
      //      <img src={instagram}/>
      //    </a>
      //  </div>

export default Navbar;
